import { render, staticRenderFns } from "./LatestNews.vue?vue&type=template&id=6b83c783&scoped=true"
import script from "./LatestNews.vue?vue&type=script&lang=ts"
export * from "./LatestNews.vue?vue&type=script&lang=ts"
import style0 from "./LatestNews.vue?vue&type=style&index=0&id=6b83c783&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b83c783",
  null
  
)

export default component.exports