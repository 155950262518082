import { render, staticRenderFns } from "./Hero.vue?vue&type=template&id=420b72a1&scoped=true"
import script from "./Hero.vue?vue&type=script&lang=ts"
export * from "./Hero.vue?vue&type=script&lang=ts"
import style0 from "./Hero.vue?vue&type=style&index=0&id=420b72a1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "420b72a1",
  null
  
)

export default component.exports